





















































import { Component, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

type Credentials = {
  login: null | string;
  password: null | string;
  remember?: boolean;
};

const GUEST_CREDENTIALS: Credentials = {
  login: "guest",
  password: "password"
};

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    LoginForm: () => import("@/app/modules/auth/components/LoginForm.vue")
  }
})
export default class LoginPage extends Vue {
  constructor() {
    super();

    GA.addCustomDimension(1, `Unauthenticated user`);
    GA.trackView("Login");
  }

  credentials: Credentials = {
    login: localStorage.getItem("login"),
    password: localStorage.getItem("password"),
    remember: !!localStorage.getItem("login") && !!localStorage.getItem("password")
  };

  errors = null;

  get appData() {
    return this.$store.getters["app/data"];
  }

  get appLayout() {
    try {
      return this.appData.layout;
    } catch (e) {
      return null;
    }
  }

  get loginButtonTitle() {
    return `${this.$t("AUTH.LOGIN")} ${this.appData.name}`;
  }

  getToken({ guest = false }) {
    this.$store
      .dispatch("auth/login", guest ? GUEST_CREDENTIALS : this.credentials)
      .then(({ data }) => {
        this.rememberCredentials();

        this.$store.commit("auth/setApiToken", data.api_token);
        this.$router.push({ name: "home" });
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      });
  }

  rememberCredentials() {
    if (this.credentials.remember) this.$store.commit("auth/saveCredentials", this.credentials);
    else this.$store.commit("auth/destroyCredentials");
  }
}
